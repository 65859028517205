var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info w-full" },
    [
      !_vm.isLoading
        ? _c(
            "vx-card",
            [
              _c("div", { staticClass: "data-block-heading" }, [
                _c(
                  "h3",
                  {
                    staticClass: "data-block-heading__button",
                    on: {
                      click: function ($event) {
                        return _vm.$serverBus.$emit("fetch-data-in-blocks", {
                          query: "bq-visitors-journeys",
                        })
                      },
                    },
                  },
                  [
                    _c("DownArrowIcon", {
                      style:
                        "transform: rotate(" +
                        (_vm.isCollapsedVisitorsJourneysBlock
                          ? "0deg"
                          : "180deg") +
                        "); margin-right: 12px;",
                      attrs: { width: 14, height: 14 },
                    }),
                    _vm._v("\n        Visitors Journeys\n      "),
                  ],
                  1
                ),
              ]),
              !_vm.isCollapsedVisitorsJourneysBlock
                ? [
                    _c("div", { staticClass: "chart-info-table" }, [
                      _vm.modifiedUserFlowTree &&
                      _vm.modifiedUserFlowTree.length > 0
                        ? _c("table", [
                            _c("thead", [
                              _c(
                                "tr",
                                [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("views.home.originalCampaign")
                                      )
                                    ),
                                  ]),
                                  _vm._l(
                                    Array.from({ length: 3 }, function (_, i) {
                                      return i + 1
                                    }).reverse(),
                                    function (i) {
                                      return _c("th", { key: i }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("views.home.previousSubsite")
                                          ) +
                                            " " +
                                            _vm._s(i)
                                        ),
                                      ])
                                    }
                                  ),
                                  _c(
                                    "th",
                                    { staticStyle: { "min-width": "60px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("views.home.targetSubsite")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _c(
                              "tbody",
                              [
                                _c(
                                  "tr",
                                  [
                                    _vm._l(4, function (i) {
                                      return _c("td", { key: "empty-" + i })
                                    }),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedVegaUserFlow.url
                                            ? _vm.removeDomain(
                                                _vm.selectedVegaUserFlow.url
                                              )
                                            : ""
                                        ) +
                                          " (" +
                                          _vm._s(_vm.totalUniqueVisitors) +
                                          ")"
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                                _vm._l(
                                  _vm.modifiedUserFlowTree,
                                  function (row, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key:
                                          _vm.modifiedUserFlowTree +
                                          "-" +
                                          index,
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(_vm._s(row.utmCampaign || "")),
                                        ]),
                                        _vm._l(
                                          row.path,
                                          function (pathItem, rowIndex) {
                                            return _c(
                                              "td",
                                              {
                                                key:
                                                  index +
                                                  "-" +
                                                  rowIndex +
                                                  "-path",
                                                style:
                                                  "" +
                                                  (pathItem
                                                    ? "cursor: pointer;"
                                                    : ""),
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickByPathItemInUserFlow(
                                                      row,
                                                      pathItem,
                                                      rowIndex
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      pathItem
                                                        ? pathItem +
                                                            " (" +
                                                            row.count +
                                                            ")"
                                                        : ""
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _c("td"),
                                      ],
                                      2
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ])
                        : _c("div", { staticClass: "pb-3 text-center" }, [
                            _vm._v(
                              _vm._s(_vm.$t("views.home.noDataAvailable"))
                            ),
                          ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }